<template>
  <div class="d-flex justify-center">
    <v-container class="d-flex justify-center flex-column align-center">
      <router-view />
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
::-webkit-scrollbar {
  width: 4px;
  margin-left: 1px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: 25px;
  padding-right: 0px;
}
</style>
