<template>
  <v-dialog :width="1000" persistent scrollable v-model="dialog">
    <v-card style="min-height: 80vh" class="elevation-0 rounded-0">
      <!-- Cabeçalho -->
      <v-card-title class="text-h5">
        <span class="mr-2">{{ event.tipo | tipoEvento }} </span>
        <v-icon size="x-large" class="mr-2">
          {{ eventoMultiplo ? "mdi-vector-union" : "mdi-vector-arrange-below" }}
        </v-icon>
      </v-card-title>
      <!-- Fomulário -->
      <v-card-text class="pt-0 pb-4">
        <v-form ref="form" v-model="isFormValid">
          <v-row>
            <v-col :cols="7" class="campos campos-form pt-6 pb-0">
              <v-row>
                <v-col cols="12" sm="4" class="py-0" v-if="typeDay != 1">
                  <v-text-field
                    dense
                    outlined
                    min="1"
                    suffix="dia(s)"
                    type="number"
                    color="deep-purple accent-2"
                    v-model="camposEvento.days"
                    :rules="[regras.exigido, () => camposEvento.days > 0 || 'Mínimo 1 dia']"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" :sm="typeDay == 1 ? '12' : '8'" class="py-0">
                  <v-select
                    dense
                    outlined
                    item-text="text"
                    item-value="index"
                    color="deep-purple accent-2"
                    :items="typesDays"
                    v-model="typeDay"
                  ></v-select>
                </v-col>

                <v-col cols="12" class="py-0">
                  <v-text-field
                    dense
                    outlined
                    label="Nome do evento"
                    color="deep-purple accent-2"
                    autofocus
                    v-model="camposEvento.name"
                    :rules="regras.exigido"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" class="py-0">
                  <v-autocomplete
                    :loading="loadingTemplate"
                    clearable
                    v-model="templateEmailSelecionado"
                    :items="templates.objects"
                    @click:clear="removeTemplate"
                    :search-input.sync="buscaTemplate"
                    dense
                    outlined
                    label="Template"
                    item-text="nome"
                    item-value="id"
                    ref="template_email_id"
                    return-object
                    no-data-text="Nenhum template encontrado"
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" class="pb-0">
                  <v-textarea
                    dense
                    outlined
                    hide-details
                    label="Observação"
                    color="deep-purple accent-2"
                    v-model="camposEvento.obs"
                    rows="2"
                  ></v-textarea>
                </v-col>

                <v-card-title
                  v-if="mostrarCampoCallback"
                  class="text-black text-subtitle-1 font-weight-bold d-flex align-center justify-space-between flex-grow-1"
                >
                  Chamada callback
                  <v-btn icon small @click="desativaCallback">
                    <v-icon> mdi-close </v-icon>
                  </v-btn>
                </v-card-title>

                <v-col cols="12" class="mt-3 pb-0" v-if="mostrarCampoCallback">
                  <v-text-field
                    dense
                    outlined
                    persistent-hint
                    label="URL para callback"
                    hint="Será realizado um POST contendo a evidência da notificação em JSON"
                    ref="url_callback"
                    color="deep-purple accent-2"
                    v-model="camposEvento.url_callback"
                    rows="1"
                    style="margin-top: -25px"
                    :rules="regras.exigido"
                  ></v-text-field>
                </v-col>

                <v-card-title
                  v-if="mostrarCampoNps && !mostrarCampoPesquisa"
                  class="text-black text-subtitle-1 font-weight-bold d-flex align-center justify-space-between flex-grow-1"
                >
                  NPS
                  <v-btn icon small @click="desativaNps">
                    <v-icon> mdi-close </v-icon>
                  </v-btn>
                </v-card-title>

                <v-col cols="12" class="py-0" v-if="mostrarCampoNps && !mostrarCampoPesquisa">
                  <v-select
                    dense
                    outlined
                    label="Selecione uma NPS"
                    color="deep-purple accent-2"
                    :items="listaNps"
                    v-model="npsSelecionada"
                    class="select-nps"
                    no-data-text="Nenhuma NPS cadastrada"
                    hint="Atenção: Uma NPS já respondida não gera uma nova notificação"
                    persistent-hint
                  ></v-select>
                </v-col>

                <v-card-title
                  v-if="!mostrarCampoNps && mostrarCampoPesquisa"
                  class="text-black text-subtitle-1 font-weight-bold d-flex align-center justify-space-between flex-grow-1"
                >
                  Pesquisa
                  <v-btn icon small @click="desativaPesquisa">
                    <v-icon> mdi-close </v-icon>
                  </v-btn>
                </v-card-title>

                <v-col cols="12" class="py-0" v-if="!mostrarCampoNps && mostrarCampoPesquisa">
                  <v-select
                    dense
                    outlined
                    label="Selecione uma pesquisa"
                    color="deep-purple accent-2"
                    :items="listaPesquisas"
                    item-text="titulo"
                    item-value="id"
                    v-model="pesquisaSelecionada.id"
                    class="select-pesquisa"
                    no-data-text="Nenhuma pesquisa cadastrada"
                    hint="Atenção: Uma pesquisa já respondida não gera uma nova notificação"
                    persistent-hint
                  ></v-select>
                </v-col>

                <!-- Inicio do bloco de multiplos vencimentos -->
                <v-card-title
                  v-if="eventoMultiplo"
                  class="text-black text-subtitle-1 font-weight-bold d-flex align-center justify-space-between flex-grow-1"
                >
                  {{ definirNomePorTipoRegua }}
                  <v-btn icon small @click="toggleEventoMultiplo">
                    <v-icon> mdi-close </v-icon>
                  </v-btn>
                </v-card-title>

                <v-col cols="12" class="py-0" v-if="eventoMultiplo">
                  <v-autocomplete
                    :loading="loadingTemplateMultiplo"
                    clearable
                    v-model="templateEmailMultiploSelecionado"
                    :items="templatesMultiplos.objects"
                    :search-input.sync="buscaTemplateMultiplo"
                    @click:clear="removeTemplateMultiplo"
                    ref="em_template_email_id"
                    dense
                    outlined
                    label="Template"
                    item-text="nome"
                    item-value="id"
                    return-object
                    no-data-text="Nenhum template encontrado"
                  >
                  </v-autocomplete>
                </v-col>

                <!-- Fim do bloco de multiplos vencimentos -->
                <v-btn
                  color="primary"
                  text
                  class="mt-3"
                  small
                  @click="mostrarCampoCallback = !mostrarCampoCallback"
                  v-if="!mostrarCampoCallback"
                >
                  Adicionar callback
                </v-btn>

                <v-btn
                  color="primary"
                  text
                  class="mt-3"
                  small
                  @click="mostrarCampoNps = !mostrarCampoNps"
                  v-if="!mostrarCampoNps && !eventoMultiplo && !mostrarCampoPesquisa"
                >
                  Adicionar NPS
                </v-btn>

                <v-btn
                  color="primary"
                  text
                  class="mt-3"
                  small
                  @click="mostrarCampoPesquisa = !mostrarCampoPesquisa"
                  v-if="!mostrarCampoPesquisa && !eventoMultiplo && !mostrarCampoNps"
                >
                  Adicionar Pesquisa
                </v-btn>

                <v-btn
                  color="primary"
                  text
                  class="mt-3"
                  small
                  @click="toggleEventoMultiplo"
                  v-if="
                    !mostrarCampoNps &&
                    !mostrarCampoPesquisa &&
                    !eventoMultiplo &&
                    reguasComEventoMultiplo.includes(regua.tipo)
                  "
                >
                  Ativar {{ definirNomePorTipoRegua }}
                </v-btn>

                <v-col cols="12" sm="12" class="py-0" v-if="this.event.id">
                  <TabelaAnexos :anexos="templateEmailAnexos" />
                  <TabelaAnexos
                    v-if="eventoMultiplo"
                    :multiplo="true"
                    :anexos="templateEmailMultiploAnexos"
                  />
                </v-col>

                <!-- Horarios programados -->
                <v-row>
                  <v-col cols="12">
                    <ProgramarHorario :key="event.id" :event="event" ref="horarios" class="pt-4" />
                  </v-col>
                </v-row>
              </v-row>
            </v-col>

            <!-- Pré visualização -->
            <v-col class="pb-0" cols="5">
              <PreviewEmailTemplate
                style="width: 100%"
                :eventoMultiplo="eventoMultiplo"
                :camposEvento="camposEvento"
                :templateEmailMultiploHTML="templateEmailMultiploHTML"
                :templateEmailHTML="templateEmailHTML"
                :templateEmailMultiploAssunto="templateEmailMultiploAssunto"
                :templateEmailAssunto="templateEmailAssunto"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <!-- Ações -->

      <v-card-actions class="mb-3 mr-3 flex-column align-end">
        <div>
          <v-btn color="primary" text @click="fecharDialog()"> Cancelar </v-btn>
          <v-btn color="primary" @click="save" :disabled="!isFormValid" :loading="salvando">
            Salvar
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import npsService from "@/services/nps";
import pesquisaService from "@/services/pesquisas";
import TabelaAnexos from "../../TabelaAnexos.vue";
import rules from "@/assets/js/rules";
import { debounce } from "@/assets/js/utils";
import emailService from "@/services/emails.js";
import PreviewEmailTemplate from "../../previewEmailTemplate/PreviewEmailTemplate.vue";
import ProgramarHorario from "./ProgramarHorario.vue";

export default {
  name: "DialogEmail",
  components: {
    TabelaAnexos,
    PreviewEmailTemplate,
    ProgramarHorario,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    event: {
      type: Object,
      default: {},
    },
  },
  data: () => ({
    salvando: false,
    isFormValid: false,
    itemsCampaha: [],
    listaNps: [],
    listaPesquisas: [],
    npsSelecionada: {},
    pesquisaSelecionada: {},
    campoSelecionado: null,
    regras: rules,
    templateEmailAnexos: [],
    templateEmailMultiploAnexos: [],
    templateEmailHTML: null,
    templateEmailMultiploHTML: null,
    templateEmailSelecionado: { id: null, nome: null },
    templateEmailMultiploSelecionado: { id: null, nome: null },
    templateEmailAssunto: null,
    templateEmailMultiploAssunto: null,
    loadingTemplate: false,
    loadingTemplateMultiplo: false,
    loadingTemplateHTML: false,

    reguasComEventoMultiplo: [
      "VENCIMENTO_BOLETO",
      "VENCIMENTO_DEBITO",
      "VENCIMENTO_CREDITO",
      "VENCIMENTO_PIX",
      "TOLERANCIA_BOLETO",
      "TOLERANCIA_DEBITO",
      "TOLERANCIA_CREDITO",
      "TOLERANCIA_PIX",
      "PAGAMENTO_BOLETO_RECEBIDO",
      "PAGAMENTO_DEBITO_RECEBIDO",
      "PAGAMENTO_CREDITO_RECEBIDO",
      "PAGAMENTO_PIX_RECEBIDO",
      "IMPLANTACAO",
    ],

    typeDay: 1,
    camposEvento: {
      days: null,
      name: null,
      obs: null,
      template_email_id: null,
      url_callback: null,
      multiplo: null,
      em_template_email_id: null,
    },

    mostrarCampoCallback: false,
    mostrarCampoNps: false,
    mostrarCampoPesquisa: false,
    eventoMultiplo: false,
    buscaTemplate: "",
    buscaTemplateMultiplo: "",
    templates: [],
    templatesMultiplos: [],
  }),
  computed: {
    ...mapState("regua", ["regua"]),
    ...mapGetters("empresa", ["empresaSelecionada"]),

    typesDays() {
      const tipos = [
        {
          tipo: "IMPLANTACAO",
          dias: [
            { index: 1, text: "Na implantação" },
            { index: 2, text: "Depois da implantação" },
          ],
        },
        {
          tipo: "VENCIMENTO_BOLETO",
          dias: [
            { index: 0, text: "Antes do vencimento" },
            { index: 1, text: "No vencimento" },
            { index: 2, text: "Depois do vencimento" },
          ],
        },
        {
          tipo: "VENCIMENTO_DEBITO",
          dias: [
            { index: 0, text: "Antes do vencimento" },
            { index: 1, text: "No vencimento" },
            { index: 2, text: "Depois do vencimento" },
          ],
        },
        {
          tipo: "VENCIMENTO_CREDITO",
          dias: [
            { index: 0, text: "Antes do vencimento" },
            { index: 1, text: "No vencimento" },
            { index: 2, text: "Depois do vencimento" },
          ],
        },
        {
          tipo: "VENCIMENTO_PIX",
          dias: [
            { index: 0, text: "Antes do vencimento" },
            { index: 1, text: "No vencimento" },
            { index: 2, text: "Depois do vencimento" },
          ],
        },
        {
          tipo: "TOLERANCIA_BOLETO",
          dias: [
            { index: 0, text: "Antes da tolerância" },
            { index: 1, text: "Na tolerância" },
            { index: 2, text: "Depois da tolerância" },
          ],
        },
        {
          tipo: "TOLERANCIA_DEBITO",
          dias: [
            { index: 0, text: "Antes da tolerância" },
            { index: 1, text: "Na tolerância" },
            { index: 2, text: "Depois da tolerância" },
          ],
        },
        {
          tipo: "TOLERANCIA_CREDITO",
          dias: [
            { index: 0, text: "Antes da tolerância" },
            { index: 1, text: "Na tolerância" },
            { index: 2, text: "Depois da tolerância" },
          ],
        },{
          tipo: "TOLERANCIA_PIX",
          dias: [
            { index: 0, text: "Antes da tolerância" },
            { index: 1, text: "Na tolerância" },
            { index: 2, text: "Depois da tolerância" },
          ],
        },
        {
          tipo: "ANIVERSARIO_DO_CLIENTE",
          dias: [
            { index: 0, text: "Antes do aniversário" },
            { index: 1, text: "No aniversário" },
            { index: 2, text: "Depois do aniversário" },
          ],
        },
        {
          tipo: "BOLETO_SOLICITADO",
          dias: [
            { index: 1, text: "Na solicitação" },
            { index: 2, text: "Depois da solicitação" },
          ],
        },
        {
          tipo: "INICIO_CONTRATO",
          dias: [
            { index: 0, text: "Antes do início" },
            { index: 1, text: "No início" },
            { index: 2, text: "Depois do início" },
          ],
        },
        {
          tipo: "CONTRATO_FINALIZADO",
          dias: [
            { index: 1, text: "Na finalização" },
            { index: 2, text: "Depois da finalização" },
          ],
        },
        {
          tipo: "CONTRATO_CANCELADO",
          dias: [
            { index: 1, text: "No cancelamento" },
            { index: 2, text: "Depois do cancelamento" },
          ],
        },
        {
          tipo: "PAGAMENTO_BOLETO_RECEBIDO",
          dias: [
            { index: 1, text: "No pagamento" },
            { index: 2, text: "Depois do pagamento" },
          ],
        },
        {
          tipo: "PAGAMENTO_DEBITO_RECEBIDO",
          dias: [
            { index: 1, text: "No pagamento" },
            { index: 2, text: "Depois do pagamento" },
          ],
        },
        {
          tipo: "PAGAMENTO_CREDITO_RECEBIDO",
          dias: [
            { index: 1, text: "No pagamento" },
            { index: 2, text: "Depois do pagamento" },
          ],
        },
        {
          tipo: "PAGAMENTO_PIX_RECEBIDO",
          dias: [
            { index: 1, text: "No pagamento" },
            { index: 2, text: "Depois do pagamento" },
          ],
        },
        {
          tipo: "PARCELA_BOLETO_CRIADA",
          dias: [
            { index: 1, text: "Na criação" },
            { index: 2, text: "Depois da criação" },
          ],
        },
        {
          tipo: "PARCELA_DEBITO_CRIADA",
          dias: [
            { index: 1, text: "Na criação" },
            { index: 2, text: "Depois da criação" },
          ],
        },
        {
          tipo: "PARCELA_CREDITO_CRIADA",
          dias: [
            { index: 1, text: "Na criação" },
            { index: 2, text: "Depois da criação" },
          ],
        },
        {
          tipo: "PARCELA_PIX_CRIADA",
          dias: [
            { index: 1, text: "Na criação" },
            { index: 2, text: "Depois da criação" },
          ],
        },
        {
          tipo: "TERMINO_CONTRATO",
          dias: [
            { index: 0, text: "Antes do término" },
            { index: 1, text: "No término" },
            { index: 2, text: "Depois do término" },
          ],
        },
        {
          tipo: "MOVIMENTACAO",
          dias: [
            { index: 0, text: "Antes da movimentação" },
            { index: 1, text: "Na movimentação" },
            { index: 2, text: "Depois da movimentação" },
          ],
        },
      ];
      let tipoSelecionado = tipos.find(
        (tipos) => tipos.tipo == this.regua.tipo
      );
      return tipoSelecionado?.dias;
    },

    definirNomePorTipoRegua() {
      if (this.regua.tipo === "IMPLANTACAO") {
        return "Múltiplas Implantações";
      }
      return "Múltiplos Vencimentos";
    },
  },

  watch: {
    "templateEmailSelecionado.id": async function (newVal) {
      if (newVal) {
        await this.carregaTemplate(newVal);
        const template = await this.carregaTemplateHTML(newVal);
        this.templateEmailHTML = template;
      } else {
        this.templateEmailHTML = null;
      }
    },
    "templateEmailMultiploSelecionado.id": async function (newVal) {
      if (newVal) {
        await this.carregaTemplateMultiplo(newVal);
        const template = await this.carregaTemplateHTML(newVal);
        this.templateEmailMultiploHTML = template;
      } else {
        this.templateEmailMultiploHTML = null;
      }
    },
    buscaTemplate: debounce(function (newVal) {
      if (newVal) {
        this.carregaTemplatesEmail(newVal);
      } else {
        this.carregaTemplatesEmail();
      }
    }, 300),

    buscaTemplateMultiplo: debounce(function (newVal) {
      if (newVal) {
        this.carregaTemplatesEmailMultiplos(newVal);
      } else {
        this.carregaTemplatesEmailMultiplos(newVal);
      }
    }, 300),

    async dialog() {
      if (!this.dialog) {
        this.limpaCampos();
        return;
      }
      this.$refs.form && this.$refs.form.resetValidation();

      this.inicializarDialogSemComando();

      this.camposEvento.obs = this.event.observacao || "";
      this.camposEvento.name = this.event.nome || "";

      this.inicializarDialogDias();

      this.eventoMultiplo = this.event.multiplo;

      await this.inicializarDialogEmail();

      if (this.eventoMultiplo) {
        await this.inicializarDialogEventoMultiplo();
      }

      this.$nextTick(() => {
        this.$refs.horarios.configuraDisparos();
      });
    },
  },
  methods: {
    ...mapActions("evento", {
      salvarEvento: "salvarEvento",
      toggleEventoMultiplo: "toggleEventoMultiplo",
    }),
    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),
    ...mapActions("emails", {
      getEmails: "getEmails",
    }),

    // TEMPLATES

    async carregaTemplatesEmail(busca = "") {
      this.loadingTemplate = true;
      const payload = {
        status: "PRODUCAO",
        tipo_regua: this.regua.tipo,
        empresa_id: this.empresaSelecionada.id,
        busca: busca,
      };
      try {
        const { data } = await emailService.get(payload);
        this.templates = data;
      } catch (err) {
        this.showSnackBar({
          text: "Erro ao carregar templates de E-mail",
        });
      } finally {
        this.loadingTemplate = false;
      }
    },

    async carregaTemplatesEmailMultiplos(busca = "") {
      const payload = {
        status: "PRODUCAO",
        tipo_regua: this.regua.tipo,
        empresa_id: this.empresaSelecionada.id,
        busca: busca,
      };

      this.loadingTemplateMultiplo = true;
      try {
        const { data } = await emailService.get(payload);
        this.templatesMultiplos = data;
      } catch (err) {
        this.showSnackBar({
          text: "Erro ao carregar templates de E-mail",
        });
      } finally {
        this.loadingTemplateMultiplo = false;
      }
    },

    async carregaTemplateHTML(id = null) {
      this.loadingTemplateHTML = true;
      try {
        const { data } = await emailService.renderizar(id, {
          empresa_id: this.empresaSelecionada.id,
          tipo_regua: this.regua.tipo,
        });
        return data;
      } catch (err) {
        console.error(err);
        this.showSnackBar({
          text: "Erro ao carregar conteúdo do template",
        });
      } finally {
        this.loadingTemplateHTML = false;
      }
    },

    removeTemplate() {
      this.templateEmailSelecionado = {
        nome: null,
        id: null,
      };
    },

    removeTemplateMultiplo() {
      this.templateEmailMultiploSelecionado = {
        nome: null,
        id: null,
      };
    },

    async carregaTemplate(templateId) {
      this.loadingTemplate = true;
      try {
        const { data } = await emailService.get({
          status: "PRODUCAO",
          tipo_regua: this.regua.tipo,
          empresa_id: this.empresaSelecionada.id,
          id: templateId,
          incluir: "anexos",
        });

        this.templateEmailSelecionado = {
          nome: data.objects[0].nome,
          id: data.objects[0].id,
        };
        this.templates = data;
        this.templateEmailAssunto = data.objects[0].assunto;
        this.templateEmailAnexos = [...data.objects[0].anexos];
      } catch (err) {
        this.showSnackBar({
          text: "Erro ao carregar template de E-mail",
        });
      } finally {
        this.loadingTemplate = false;
      }
    },

    async carregaTemplateMultiplo(templateId) {
      this.loadingTemplateMultiplo = true;
      try {
        const { data } = await emailService.get({
          status: "PRODUCAO",
          tipo_regua: this.regua.tipo,
          empresa_id: this.empresaSelecionada.id,
          id: templateId,
          incluir: "anexos",
        });

        this.templateEmailMultiploSelecionado = {
          nome: data.objects[0].nome,
          id: data.objects[0].id,
        };
        this.templatesMultiplos = data;
        this.templateEmailMultiploAssunto = data.objects[0].assunto;
        this.templateEmailMultiploAnexos = [...data.objects[0].anexos];
      } catch (err) {
        this.showSnackBar({
          text: "Erro ao carregar template de E-mail",
        });
      } finally {
        this.loadingTemplateMultiplo = false;
      }
    },

    estilosIFrame() {
      const frame = this.$refs.iframeContent.contentWindow;
      const css = document.createElement("style");
      css.textContent = `
          ::-webkit-scrollbar {
            width: 4px;
            margin-left: 1px;
          }
          ::-webkit-scrollbar-track {
            background: transparent;
          }
          ::-webkit-scrollbar-thumb {
            background-color: #bdbdbd;
            border-radius: 25px;
            padding-right: 0px;
          }
          button, a {
            pointer-events: none;
          }
        `;
      frame.document.head.appendChild(css);
    },

    // DIALOG

    async inicializarDialogEmail() {
      this.carregaTemplatesEmail();
      this.carregaTemplatesEmailMultiplos();
      this.templateEmailSelecionado.id = this.event.template_email_id || null;

      if (this.event.template_email_id) {
        await this.carregaTemplate(this.event.template_email_id);
      }
    },

    async inicializarDialogEventoMultiplo() {
      this.camposEvento.multiplo = this.event.multiplo;

      this.templateEmailMultiploSelecionado.id = this.event.evento_multiplo.template_email_id || "";

      if (this.event.evento_multiplo.template_email_id) {
        await this.carregaTemplateMultiplo(this.event.evento_multiplo.template_email_id);
      }
    },

    inicializarDialogSemComando() {
      this.carregaNps();
      this.carregaPesquisa();
      this.npsSelecionada = this.event.nps || "";
      this.pesquisaSelecionada = this.event.pesquisa || {};
      this.camposEvento.url_callback = this.event.url_callback || "";
      this.mostrarCampoCallback = !!this.camposEvento.url_callback;
      this.mostrarCampoNps = this.event.nps ? Object.keys(this.event.nps).length > 0 : false;
      this.mostrarCampoPesquisa = this.event?.pesquisa
        ? Object.keys(this.event.pesquisa).length > 0
        : false;
    },
    inicializarDialogDias() {
      if (this.event.dia != 0) {
        if (this.event.dia > 0) {
          this.typeDay = 2;
          this.camposEvento.days = this.event.dia;
        } else if (this.event.dia < 0) {
          this.typeDay = 0;
          this.camposEvento.days = this.event.dia * -1;
        }
      }
    },

    fecharDialog() {
      this.limpaCampos();
      this.$emit("update:dialog", false);
    },

    // NPS

    async carregaNps() {
      let params = {
        empresa_id: this.empresaSelecionada.id,
        status: "PRODUCAO",
      };
      this.listaNps = [];

      try {
        const { data } = await npsService.getNps(params);
        data.forEach((nps) => {
          this.listaNps.push({
            text: nps.pergunta,
            value: nps,
          });
        });
      } catch (e) {
        console.error(e);
        this.showSnackBar({
          text: "Erro ao carregar NPS",
        });
      }
    },

    desativaNps() {
      this.mostrarCampoNps = false;
      this.npsSelecionada = {};
      this.event.nps_id = null;
    },

    // PESQUISA

    async carregaPesquisa() {
      let params = {
        empresa_id: this.empresaSelecionada.id,
        status: "PRODUCAO",
      };
      this.listaPesquisas = [];

      try {
        const { data } = await pesquisaService.get(params);
        this.listaPesquisas = data;
      } catch (e) {
        console.error(e);
        this.showSnackBar({
          text: "Erro ao carregar pesquisas",
        });
      }
    },

    desativaPesquisa() {
      this.mostrarCampoPesquisa = false;
      this.pesquisaSelecionada = {};
      this.event.pesquisa_id = null;
    },

    // CALLBACK

    desativaCallback() {
      this.mostrarCampoCallback = false;
      this.camposEvento.url_callback = null;
      this.event.url_callback = null;
    },

    // MISC

    async save(evento) {
      console.debug("Salvando o evento");
      evento.preventDefault();
      let payload = {};

      if (!this.$refs.form.validate()) return;
      if (this.salvando) return;

      this.salvando = true;

      const defaultPayload = {
        id: null,
        dia: this.dayByPayload(),
        nome: this.camposEvento.name || this.prepareData(this.event.nome),
        observacao: this.camposEvento.obs || this.prepareData(this.event.observacao),
        tipo: this.prepareData(this.event.tipo),
        regua_id: this.regua.id,
        status: this.prepareData(this.event.status) || "RASCUNHO",
        pesquisa_id: this.pesquisaSelecionada.id || this.prepareData(this.pesquisaSelecionada.id),
        url_callback: this.camposEvento.url_callback || this.prepareData(this.event.url_callback),
        nps_id: this.npsSelecionada.id || this.prepareData(this.npsSelecionada.id),
        evento_multiplo: null,
        ...this.$refs.horarios.montaPayloadDisparos(),
      };

      payload = {
        ...defaultPayload,
        template_email_id: this.templateEmailSelecionado?.id || null,
        multiplo: this.eventoMultiplo,
      };

      if (this.event.id) payload.id = this.event.id;
      else delete payload["id"];

      if (this.eventoMultiplo) {
        payload.evento_multiplo = {
          template_email_id: this.templateEmailMultiploSelecionado?.id || null,
        };
      }

      try {
        const response = await this.salvarEvento(payload);
        console.debug("Evento salvo:", response.id);
        this.$emit("edit", response);
        this.fecharDialog();
        this.showSnackBar({
          text: "Evento " + (this.event.id ? "editado" : "criado") + " com sucesso!",
        });
        this.mostrarCampoCallback = false;
        this.mostrarCampoNps = false;
        this.mostrarCampoPesquisa = false;
      } catch (err) {
        console.error("Não foi possível salvar o evento", err);
        this.showSnackBar({
          text: "Erro ao " + (this.event.id ? "editar" : "criar") + " o evento!",
        });
      } finally {
        this.salvando = false;
      }
    },

    toggleEventoMultiplo() {
      this.eventoMultiplo = !this.eventoMultiplo;

      if (!this.eventoMultiplo) {
        this.templateEmailMultiploSelecionado = {
          nome: null,
          id: null,
        };
      } else {
        this.isFormValid = false;
      }
    },

    prepareData(item) {
      return typeof item === "undefined" || typeof item === null ? null : item;
    },

    dayByPayload() {
      switch (this.typeDay) {
        case 0:
          return this.camposEvento.days ? this.camposEvento.days * -1 : null;
        case 1:
          return 0;
        case 2:
          return this.camposEvento.days || null;
        default:
          return null;
      }
    },

    limpaCampos() {
      for (const key of Object.keys(this.camposEvento)) {
        this.camposEvento[key] = null;
      }
      this.templateEmailHTML = null;
      this.templateEmailMultiploHTML = null;
      this.templateEmailSelecionado = { id: null, nome: null };
      this.templateEmailMultiploSelecionado = { id: null, nome: null };

      this.typeDay = 1;
      this.npsSelecionada = null;
      this.pesquisaSelecionada = null;
      this.mostrarCampoCallback = false;
      this.mostrarCampoNps = false;
      this.mostrarCampoPesquisa = false;
      this.eventoMultiplo = false;
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style scoped lang="scss">
.ml-pre-visualizacao-multiplo {
  word-break: break-word;
  background-color: #e3f2fd;
  border-radius: 4px;
  font-size: 13px;
  padding: 8px 8px 4px;
  position: relative;
}

.ml-pre-visualizacao-multiplo::before {
  background-color: #e3f2fd;
  border-radius: 4px;
  content: "Pré-visualização para múltiplos";
  font-size: 10px;
  font-weight: 500;
  padding: 0 8px;
  position: absolute;
  left: 0px;
  top: -10px;
}
::-webkit-scrollbar {
  width: 4px;
  margin-left: 2px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: 25px;
  padding-right: 0px;
}

.text-black {
  color: black;
}

.select-nps,
.select-pesquisa {
  max-width: 625px;
}

.campos-form {
  scroll-behavior: smooth;
}

.preview {
  max-width: 500px;
}
</style>
