import { render, staticRenderFns } from "./DialogPush.vue?vue&type=template&id=e023fc66&scoped=true"
import script from "./DialogPush.vue?vue&type=script&lang=js"
export * from "./DialogPush.vue?vue&type=script&lang=js"
import style0 from "./DialogPush.vue?vue&type=style&index=0&id=e023fc66&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e023fc66",
  null
  
)

export default component.exports