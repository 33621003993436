<template>
  <v-dialog
    v-model="dialog"
    v-if="fatura"
    width="1200"
    @click:outside="$emit('fechar')"
  >
    <v-card>
      <v-card-title class="text-h5 blue-grey lighten-5">
        Fatura #{{ fatura.id }} de
        <span>&nbsp;</span>
        <span class="inicio-fatura">
          {{ dialogTitle }}
        </span>
        <v-spacer></v-spacer>
        <v-btn class="botao-fechar" text @click="$emit('fechar')">
          <v-icon style="font-size: 26px">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <div class="d-flex" v-if="itensFatura?.objects">
        <v-col>
          <div class="ml-1">
            <p class="mb-0 font-weight-bold text-h3 mt-4 total">
              {{
                parseFloat(fatura?.subtotal - fatura?.descontos) | fomatadorReal
              }}
            </p>
            <p class="subtitle-ml mb-7 font-weight-regular">
              Apurado de
              {{ this.fatura.periodo_inicio | moment("DD [de] MMMM") }}
              até
              {{ this.fatura.periodo_fim | moment("DD [de] MMMM") }}
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on"
                    >mdi-help-circle-outline</v-icon
                  >
                </template>
                <span>O valor é calculado no final do dia.</span>
              </v-tooltip>
            </p>
            <div class="d-flex">
              <div v-if="franquia">
                <p class="mb-0 font-weight-bold text-h5">
                  {{ franquia?.total | fomatadorReal }}
                </p>
                <p class="subtitle-ml mb-0 font-weight-regular">
                  {{ franquia.quantidade | numero }}
                  {{ franquia.quantidade > 1 ? "orquestrados" : "orquestrado" }}
                </p>
                <p
                  v-if="empresaSelecionada?.tipo_calculo_franquia == 'DIRETA'"
                  class="subtitle-ml mb-0 font-weight-regular"
                >
                  {{ descricaoValorFranquia }}
                </p>
                <template v-if="fatura.descontos > 1">
                  <p class="mb-0 font-weight-bold text-h5">
                    {{ -parseFloat(fatura.descontos) | fomatadorReal }}
                  </p>
                  <p class="subtitle-ml mb-0 font-weight-regular">
                    Desconto em fatura
                  </p>
                </template>
              </div>
              <div class="d-flex flex-column justify-center align-center mx-4">
                <div class="divisor_fatura"></div>
                <span class="divisor_plus">+</span>
                <div class="divisor_fatura"></div>
              </div>
              <div>
                <p class="mb-0 font-weight-bold text-h5">
                  {{ totalEventos.total | fomatadorReal }}
                </p>
                <p class="subtitle-ml mb-0 font-weight-regular">
                  {{ totalEventos.eventos | numero }} Eventos
                </p>
              </div>
            </div>
          </div>
        </v-col>
        <v-col>
          <GraficoServicos
            v-if="mostrarGraficoPiza"
            ref="dialogFecharFatura"
            :itensFatura="itensFaturaFiltrado"
            :carregando="false"
          />
        </v-col>
      </div>
      <div class="px-5 pb-0">
        <GraficoPorDia
          v-if="Object.keys(fatura?.analitico).length > 0"
          :notificacoes="analiticoFiltrado"
          :dataFatura="fatura?.periodo_fim"
          :inicioFatura="fatura?.periodo_inicio"
          :parcial="false"
        />
        <p v-else class="px-4 pt-0 pb-5 mb-0 text-center">
          Não foram encontrados dados do analítico
        </p>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import GraficoServicos from "../componentes/GraficoServicos.vue";
import GraficoPorDia from "./GraficoPorDia.vue";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    GraficoPorDia,
    GraficoServicos,
  },
  props: ["dialog", "fatura", "itensFatura", "empresa"],
  methods: {
    ...mapActions("fatura", {
      getItensFatura: "getItensFatura",
    }),
  },
  data() {
    return {
      mostrarGraficoPiza: false,
    };
  },
  watch: {
    itensFatura: {
      deep: true,
      handler() {
        this.mostrarGraficoPiza = false;
        setTimeout(() => {
          this.mostrarGraficoPiza = true;
        }, 100);
      },
    },
  },
  computed: {
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
    totalEventos() {
      let eventos = 0,
        total = 0;
      this.itensFatura?.objects.forEach((itemFatura) => {
        if (itemFatura.referencia != "EVENTO") return;
        eventos += itemFatura.quantidade;
        total += parseFloat(itemFatura.total);
      });
      return {
        eventos,
        total,
      };
    },
    franquia() {
      return this.itensFatura?.objects.find((item) => {
        return item.referencia == "FRANQUIA";
      });
    },
    dialogTitle() {
      let { periodo_inicio, periodo_fim } = this.fatura;
      let abertura = moment(periodo_inicio, "YYYY-MM-DD");

      return `${abertura.format("MMMM [de] YYYY")} (${abertura.format(
        "L"
      )} a ${moment(periodo_fim, "YYYY-MM-DD").format("L")})`;
    },
    analiticoFiltrado() {
      let listaAnalitico = [...this.fatura?.analitico];
      listaAnalitico = listaAnalitico.map((item) => {
        let lista = Object.entries(item);
        let listaFiltrada = lista.filter(
          ([chave, dado]) =>
            chave == "dia" || (dado.valor !== null && dado.valor !== "0.000000")
        );
        item = Object.fromEntries(listaFiltrada);
        return item;
      });
      return listaAnalitico;
    },
    itensFaturaFiltrado() {
      return (
        this.itensFatura?.objects?.filter(
          ({ referencia }) => referencia !== "DESCONTO"
        ) || []
      );
    },
    descricaoValorFranquia() {
      let valorUnitario =
        Vue.filter("fomatadorReal")(this.franquia?.valor_unitario) +
        (this.empresa?.tipo_orquestracao === "CLIENTE" ? "/cliente" : "/contrato");
      let textoDescricao = this.franquia?.descricao.replace("Franquia ", "");
      return `${valorUnitario} ${textoDescricao}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.botao-fechar {
  padding: 0 !important;
  min-width: 40px !important;
}

.inicio-fatura::first-letter {
  text-transform: uppercase;
}
.divisor_fatura {
  width: 1px;
  height: 100%;
  background: black;
}
.divisor_plus {
  line-height: 25px;
  font-size: 30px;
  font-weight: 400;
}
</style>
