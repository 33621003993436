<template>
  <v-dialog persistent v-model="dialog" :width="500">
    <v-card>
      <v-card-title class="text-h5 title-card flex align-center">
        Configurar Ordenação
      </v-card-title>
      <v-card-subtitle class="mt-0 mb-0">
        A ordenação define a ordem em que os itens serão mostrados no pdf da fatura
      </v-card-subtitle>
      <v-card-text>
        <v-list>
          <draggable
            @start="startDrag($event)"
            @end="drag = false"
            v-model="eventosOrdenados"
            v-bind="dragOptions"
            draggable=".item"
          >
            <v-hover
              v-for="evento in eventosOrdenados"
              class="item"
              :key="evento.id"
              v-slot="{ hover }"
              style="border-bottom: 1px solid #ccc"
            >
              <v-list-item :class="{ 'draggable-item': hover }" class="pl-0 pr-0">
                <v-list-item-content>
                  <v-list-item-title
                    ><v-icon v-show="hover">mdi-drag-vertical</v-icon>
                    <span :class="{ 'ml-6': !hover }"
                      >#{{ evento.id }} - {{ evento.categoria_apelido || evento.categoria }}</span
                    ></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-hover>
          </draggable>
        </v-list>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions style="position: sticky; bottom: 0; background-color: white; z-index: 1">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog()"> cancelar </v-btn>
        <v-btn color="primary" type="submit" @click="salvaNovaOrdem" :loading="salvando"> salvar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },
  data() {
    return {
      rules: {
        required: (v) => !!v || "Campo Obrigatorio",
      },
      dialog: false,
      salvando: false,
      eventos: [],
      eventosOrdenados: [],
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: "ghost",
        handle: ".draggable-item",
      };
    },
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog) {
          this.eventosOrdenados = [...this.eventos];
        }
      },
    },
  },
  computed: {
    ...mapGetters("empresa", ["empresaSelecionada"]),
  },
  methods: {
    ...mapActions("reguas", ["getReguaBrokers", "postReguaBrokers"]),
    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),

    startDrag(event) {
      this.drag = true;
    },

    async salvaNovaOrdem() {
      const payload = this.eventosOrdenados.map((item) => {
        return { id: item.id };
      });

      this.$emit("salvar", payload)
      this.closeDialog()
    },

    async openDialog() {
      this.dialog = true;
    },

    closeDialog() {
      this.dialog = false;
      this.salvando = false;
      this.eventos = [];
    },
  },
};
</script>

<style lang="scss" scoped></style>
