<template>
  <main>
    <v-app-bar app>
      <span class="text-h6 font-weight-bold ml-3"> Precificação de Eventos </span>
      <div class="flex justify-end" v-show="!carregandoPrecificacao && permissaoEditar">
        <v-btn @click="handleOrdenarEventos" color="primary" text>
          <v-icon left> mdi-sort </v-icon>
          <span> Ordenação </span>
        </v-btn>
      </div>
    </v-app-bar>

    <v-skeleton-loader
      v-if="carregandoPrecificacao"
      class="mx-auto"
      width="1000"
      type="table-tbody"
    ></v-skeleton-loader>

    <div
      v-if="editandoEvento && erroEditarPrecificacaoEvento && erroEditarPrecificacaoEvento.length"
    >
      <div v-for="err in erroEditarPrecificacaoEvento" :key="err.motivo_erro">
        <v-alert v-if="err.precificacao_id" text outlined type="error" dense>
          <span>
            <b>{{ err.precificacao_id }}</b
            >: {{ err.motivo_erro }}
          </span>
        </v-alert>
      </div>
    </div>

    <v-data-table
      v-show="!carregandoPrecificacao"
      :headers="cabecalhoTabelaEvento"
      :items="eventos"
      class="tabela elevation-1"
      no-data-text="Nenhuma precificação de eventos cadastrada."
      hide-details
      hide-default-footer
      disable-pagination
      loading-text="Carregando precificação..."
    >
      <template v-slot:headers="{ props }">
        <tr>
          <th v-for="header in props.headers" :key="header.text">
            <div class="d-flex align-center">
              <span>{{ header.text }}</span>
              <v-tooltip v-if="header.value === 'franquia'" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" class="ml-2" style="font-size: 1rem">
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>Tooltip explicando a franquia</span>
              </v-tooltip>
            </div>
          </th>
        </tr>
      </template>

      <template v-slot:[`item.id`]="{ item }">
        <span class="my-2 text--disabled">#{{ item.id }}</span>
      </template>
      <template v-slot:[`item.categoria`]="{ item }">
        <div class="flex align-center">
          <div
            class="mr-3"
            :style="`
              width:12px;
              height:12px; 
              border-radius:50%;
              background: ${item.cor || '#000000'};
            `"
          ></div>
          <div>
            <span class="my-2">{{ item.categoria || "-" }}</span>
            <br />
            <span class="my-2 text-caption text--secondary">{{
              item.categoria_apelido || "-"
            }}</span>
          </div>
        </div>
      </template>

      <template v-slot:[`item.valor`]="{ item }">
        <span class="my-2">{{ item.valor | formatadorRealDecimaisNCasas }}</span>
      </template>

      <template v-slot:[`item.franquia`]="{ item }">
        <v-checkbox disabled v-model="item.franquia"></v-checkbox>
      </template>

      <template v-if="permissaoEditar" v-slot:[`item.acoes`]="{ item }">
        <div class="flex justify-center">
          <v-icon style="font-size: 1rem" @click="handleEdicaoEvento(item)" class="edit-button"
            >mdi-pencil</v-icon
          >
        </div>
      </template>
    </v-data-table>

    <DialogPrecificacaoEvento
      @salvar="handleSalvarPrecificacaoEvento"
      ref="dialogPrecificacaoEvento"
    />
    <DialogOrdemPrecificacaoEventos @salvar="ordenarEventos" ref="dialogOrdemPrecificacaoEvento" />

    <FloatingBtn @click="handleCriacaoEvento" />
  </main>
</template>

<script>
import precificacaoService from "@/services/fatura";
import { mapGetters, mapActions } from "vuex";
import { checkCredentials } from "@/assets/js/utils";
import DialogPrecificacaoEvento from "./components/DialogPrecificacaoEvento.vue";
import DialogOrdemPrecificacaoEventos from "./components/DialogOrdemPrecificacaoEventos.vue";
import FloatingBtn from "@/components/comum/FloatingBtn.vue";
import { stringToColor } from "../../../assets/js/utils";

export default {
  components: {
    DialogPrecificacaoEvento,
    DialogOrdemPrecificacaoEventos,
    FloatingBtn,
  },
  data() {
    return {
      checkCredentials,
      carregandoPrecificacao: false,
      erroCarregarPrecificacao: false,
      cabecalhoTabelaEvento: [
        {
          text: "",
          sortable: false,
          align: "start",
          value: "id",
          width: "5%",
        },
        {
          text: "Categoria",
          sortable: false,
          align: "start",
          value: "categoria",
          width: "50%",
        },
        {
          text: "Valor",
          sortable: false,
          value: "valor",
          width: "20%",
        },
        {
          text: "Franquia",
          sortable: false,
          value: "franquia",
          width: "15%",
        },
        {
          text: "",
          sortable: false,
          value: "acoes",
          width: "15%",
          align: "end",
        },
      ],

      tab: 0,
      eventos: [],
      erroEditarPrecificacaoEvento: [],
      mudancasEvento: [],
      precificacoesEvento: [],
    };
  },
  async created() {
    this.carregandoPrecificacao = false;
    this.erroCarregarPrecificacao = false;
    await this.initPrecificacaoEventos();
  },
  methods: {
    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),

    async initPrecificacaoEventos() {
      this.limpaPrecificacoes();
      await this.carregarPrecificacao();

      const eventosSemValor = this.precificacoesEvento.filter(
        (item) => !item.valor
      );

      this.eventos = this.precificacoesEvento
        .filter((ev) => ev.valor != null && ev.valor != undefined)
        .map((obj) => ({ ...obj }))
        .sort((a, b) => {
          if (!a.ordem_exibicao && b.ordem_exibicao) {
            return -1;
          }
          if (!b.ordem_exibicao && a.ordem_exibicao) {
            return 1;
          }
        });

      eventosSemValor.forEach((item) => {
        this.eventos.push({ ...item });
      });
    },

    async ordenarEventos(payload) {
      try {
        await precificacaoService.editarOrdemPrecificacao(payload, this.empresaSelecionada.id);
        this.showSnackBar({
          text: `Eventos ordenados com sucesso!`,
        });
        await this.initPrecificacaoEventos();
      } catch (e) {
        this.showSnackBar({
          text: `Falha ao ordenar os Eventos!`,
        });
        console.error(e);
      }
    },

    handleOrdenarEventos() {
      const eventosComValor = this.eventos.filter(
        (ev) => ev.valor != null && ev.valor != undefined
      );
      this.$refs.dialogOrdemPrecificacaoEvento.eventos = eventosComValor;
      this.$refs.dialogOrdemPrecificacaoEvento.dialog = true;
    },

    async handleSalvarPrecificacaoEvento(evento) {
      if (evento.id) {
        const eventosPayload = this.eventos.map((ev) => {
          if (ev.id === evento.id) {
            ev = {
              ...ev,
              categoria: evento.categoria,
              valor: parseFloat(evento.valor),
              cor: stringToColor(evento.categoria),
              categoria_apelido: evento.categoria_apelido,
              descricao: evento.descricao,
              franquia: evento.franquia,
            };
          }
          return ev;
        });

        await this.editarPrecificacaoEventos(eventosPayload);
      } else {
        const eventoPayload = {
          categoria: evento.categoria,
          valor: parseFloat(evento.valor),
          cor: stringToColor(evento.categoria),
          categoria_apelido: evento.categoria_apelido,
          descricao: evento.descricao,
          franquia: evento.franquia,
          grupo_faturamento: this.empresaSelecionada.id,
          limite_inferior: 0,
          limite_superior: 9999,
          ordem_exibicao: 999,
        };
        await this.criarPrecificacaoEvento(eventoPayload);
      }
    },

    async carregarPrecificacao() {
      this.carregandoPrecificacao = true;
      this.erroCarregarPrecificacao = false;
      let params = {
        empresa_id: this.empresaSelecionada.id,
        limite: 1000,
      };

      try {
        const result = await precificacaoService.getPrecificacao(params);
        const precificacoes = result.data.objects;

        precificacoes.forEach((item) => {
          if (!item.categoria.includes("FRANQUIA")) {
            this.precificacoesEvento.push({ ...item });
          }
        });

        if (!this.permissaoEditar) {
          this.precificacaoEventos = this.precificacaoEventos.filter((item) => {
            return ev.valor != null && ev.valor != undefined;
          });
        }

        this.erroCarregarPrecificacao = false;
      } catch (e) {
        this.erroCarregarPrecificacao = true;
      } finally {
        this.carregandoPrecificacao = false;
      }
    },

    handleEdicaoEvento(evento) {
      this.$refs.dialogPrecificacaoEvento.evento = {
        id: evento.id,
        categoria: evento.categoria,
        descricao: evento.descricao,
        categoria_apelido: evento.categoria_apelido,
        valor: evento.valor,
        franquia: evento.franquia,
      };
      this.$refs.dialogPrecificacaoEvento.dialog = true;
    },

    handleCriacaoEvento() {
      this.$refs.dialogPrecificacaoEvento.evento = {
        id: null,
        categoria: null,
        categoria_apelido: null,
        descricao: null,
        valor: null,
        franquia: false,
      };
      this.$refs.dialogPrecificacaoEvento.dialog = true;
    },

    async editarPrecificacaoEventos(eventosPayload) {
      this.erroEditarPrecificacaoEvento = [];

      try {
        const responseEventos = await precificacaoService.editarPrecificacao(
          eventosPayload,
          this.empresaSelecionada.id
        );

        this.eventos = [...responseEventos.data];

        this.$refs.dialogPrecificacaoEvento.closeDialog();
        this.showSnackBar({
          text: `Precificação editada com sucesso !`,
        });

        this.erroEditarPrecificacaoEvento = [];
      } catch (e) {
        this.erroEditarPrecificacaoEvento = e.response?.data;
        console.error("Erro ao editar precificacao:", e);
        this.showSnackBar({
          text: `Erro ao editar precificacao`,
        });
        throw new Error();
      }
    },

    async criarPrecificacaoEvento(evento) {
      this.erroEditarPrecificacaoEvento = [];

      try {
        const responseEvento = await precificacaoService.criarPrecificacao(
          evento,
          this.empresaSelecionada.id
        );

        this.eventos.push(responseEvento.data);

        this.$refs.dialogPrecificacaoEvento.closeDialog();
        this.showSnackBar({
          text: `Precificação criada com sucesso !`,
        });

        this.erroEditarPrecificacaoEvento = [];
      } catch (e) {
        this.erroEditarPrecificacaoEvento = e.response?.data;
        console.error("Erro ao criar precificacao:", e);
        this.showSnackBar({
          text: `Erro ao criar precificacao`,
        });
      }
    },

    limpaPrecificacoes() {
      this.precificacoesEvento = [];
      this.eventos = [];
      this.erroEditarPrecificacaoEvento = [];
    },
  },
  computed: {
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
    permissaoEditar() {
      return checkCredentials(["admin", "editor_precificacao"]);
    },
    editandoEvento() {
      const editando = this.eventos.some((item) => item.editando);
      if (!editando) this.erroEditarPrecificacaoEvento = false;

      return editando;
    },
  },
};
</script>

<style scoped lang="scss">
::-webkit-scrollbar {
  width: 4px;
  margin-left: 1px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: 25px;
  padding-right: 0px;
}
.v-data-table__wrapper tr .edit-button {
  display: none;
}
.v-data-table__wrapper tr:hover .edit-button {
  display: block;
}

.tabela {
  min-width: 450px;
  width: 1000px;
}
</style>
