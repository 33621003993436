<template>
  <v-dialog :width="500" persistent scrollable v-model="dialog">
    <v-card class="elevation-0 rounded-0">
      <!-- Cabeçalho -->
      <v-card-title class="text-h5">
        <span>Teste para o evento: {{ evento.nome }}</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-divider></v-divider>
      <!-- Fomulário -->
      <v-form v-model="isFormValid" class="fill-height" ref="form" @submit.prevent="salvar">
        <v-card-text class="pt-6 pb-4">
          <div class="campo-contato">
            <v-combobox
              v-if="tipoCampoContato === 'telefone'"
              dense
              outlined
              label="Telefone"
              color="deep-purple accent-2"
              v-model="camposTeste.contato"
              :items="getContatosSalvosTeste()"
              :append-icon="''"
              required
              :rules="[...regras.exigido, ...regras.telefone]"
              v-mask="evento.tipo === 'LIGACAO_FIXO' ? '(##) ####-####' : '(##) #####-####'"
              @update:search-input="onSearch"
            >
            </v-combobox>

            <v-combobox
              v-else-if="tipoCampoContato === 'email'"
              dense
              outlined
              cache-items
              label="E-mail"
              color="deep-purple accent-2"
              v-model="camposTeste.contato"
              :items="getContatosSalvosTeste()"
              :append-icon="''"
              required
              :rules="[...regras.exigido, ...regras.email]"
            >
            </v-combobox>

            <v-combobox
              v-else-if="tipoCampoContato === 'push'"
              dense
              outlined
              label="Push"
              color="deep-purple accent-2"
              v-model="camposTeste.contato"
              required
              :rules="[...regras.exigido]"
              :items="getContatosSalvosTeste()"
              :append-icon="''"
            >
            </v-combobox>
            <v-combobox
              v-else-if="tipoCampoContato === 'comando'"
              dense
              outlined
              label="URL"
              color="deep-purple accent-2"
              v-model="camposTeste.contato"
              required
              :rules="[...regras.exigido, ...regras.url]"
              :items="getContatosSalvosTeste()"
              :append-icon="''"
            >
            </v-combobox>
          </div>

          <div class="campo-id">
            <v-text-field
              hide-spin-buttons
              dense
              outlined
              :label="labelCampoId"
              type="number"
              min="0"
              color="deep-purple accent-2"
              v-model="camposTeste.id"
              required
              :rules="[...regras.exigido]"
            >
            </v-text-field>
          </div>
        </v-card-text>

        <!-- Ações -->
        <v-spacer></v-spacer>
        <v-divider></v-divider>
        <v-card-actions class="mb-3 mr-3 flex-column align-end">
          <div>
            <v-btn color="primary" text @click="fecharDialog()"> Cancelar </v-btn>
            <v-btn color="primary" type="submit" :loading="salvando" :disabled="!isFormValid">
              Testar
            </v-btn>
          </div>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import rules from "@/assets/js/rules";
import Cookies from "js-cookie";
import enviroment from "@/assets/js/enviroment";

export default {
  name: "DialogTestarEvento",
  props: {
    regua: {
      type: Object,
      default: () => {},
    },
    evento: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      salvando: false,
      isFormValid: false,
      regras: rules,
      dialog: false,
      tipoCampoContato: null,
      tipoCampoRegua: null,
      labelCampoId: null,
      camposTeste: {
        contato: null,
        id: null,
      },
    };
  },
  computed: {
    ...mapGetters("empresa", ["empresaSelecionada"]),
  },
  methods: {
    ...mapActions("evento", {
      testarEvento: "testarEvento",
    }),

    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),

    abrirDialog() {
      this.dialog = true;
    },

    fecharDialog() {
      this.dialog = false;
    },

    definirCampoContato(evento_tipo) {
      const tiposEvento = {
        telefone: ["SMS", "LIGACAO_CELULAR", "LIGACAO_FIXO", "RCS", "WHATSAPP"],
        email: ["EMAIL"],
        push: ["PUSH"],
        comando: ["COMANDO"],
      };

      const tipoCampo = Object.entries(tiposEvento).find(([key, value]) => {
        if (value.includes(evento_tipo)) return key;
      })[0];

      this.tipoCampoContato = tipoCampo;
    },
    onSearch(v) {
      this.camposTeste.contato = v;
    },
    definirCampoId(regua_tipo) {
      const tiposRegua = {
        contrato: [
          "IMPLANTACAO",
          "INICIO_CONTRATO",
          "TERMINO_CONTRATO",
          "CONTRATO_FINALIZADO",
          "CONTRATO_CANCELADO",
        ],
        parcela: [
          "TOLERANCIA_BOLETO",
          "TOLERANCIA_DEBITO",
          "TOLERANCIA_CREDITO",
          "TOLERANCIA_PIX",
          "VENCIMENTO_BOLETO",
          "VENCIMENTO_DEBITO",
          "VENCIMENTO_CREDITO",
          "VENCIMENTO_PIX",
          "PARCELA_BOLETO_CRIADA",
          "PARCELA_DEBITO_CRIADA",
          "PARCELA_CREDITO_CRIADA",
          "PARCELA_PIX_CRIADA",
        ],
        pagamento: [
          "PAGAMENTO_DEBITO_RECEBIDO",
          "PAGAMENTO_BOLETO_RECEBIDO",
          "PAGAMENTO_CREDITO_RECEBIDO",
          "PAGAMENTO_PIX_RECEBIDO",
        ],
        solicitacao: ["BOLETO_SOLICITADO"],
        cliente: ["ANIVERSARIO_DO_CLIENTE"],
        movimentacao: ["MOVIMENTACAO"],
      };
      const tipoCampo = Object.entries(tiposRegua).find(([key, value]) => {
        if (value.includes(regua_tipo)) return key;
      })[0];

      this.tipoCampoRegua = tipoCampo;

      const genero =
        tipoCampo === "contrato" || tipoCampo === "pagamento" || tipoCampo === "cliente"
          ? "M"
          : "F";
      this.labelCampoId = `Id ${genero === "M" ? "do" : "da"} ${
        tipoCampo.charAt(0).toUpperCase() + tipoCampo.slice(1)
      }`;
    },

    definirCamposForm() {
      this.definirCampoContato(this.evento.tipo);
      this.definirCampoId(this.regua.tipo);
    },

    tratarContato() {
      if (this.tipoCampoContato === "telefone") {
        return this.camposTeste.contato.replace(/[()\s-]/g, "");
      }
      return this.camposTeste.contato;
    },
    getContatosSalvosTeste() {
      let contatosTeste = JSON.parse(Cookies.get("contatosSalvosTeste") || "false");
      if (!contatosTeste) return [];
      return contatosTeste[this.tipoCampoContato];
    },
    salvarContatoParaAutoComplete({ tipo, contato } = {}) {
      let contatosTestePorTipo = JSON.stringify({ telefone: [], email: [], push: [], comando: [] });
      let contatosTeste = JSON.parse(Cookies.get("contatosSalvosTeste") || contatosTestePorTipo);
      contatosTeste[tipo].unshift(contato);
      Object.entries(contatosTeste).forEach(([key, value]) => {
        contatosTeste[key].slice(0, 5);
      });
      Cookies.set("contatosSalvosTeste", JSON.stringify(contatosTeste), {
        domain: enviroment.cookiesDomain,
        path: "/",
      });
    },
    async salvar() {
      this.salvando = true;
      const payload = {
        empresa_id: this.empresaSelecionada.id,
        referencia_id: this.camposTeste.id,
        regua_id: this.regua.id,
        evento_id: this.evento.id,
        contato: this.tratarContato(),
        tipo_referencia: this.tipoCampoRegua.toUpperCase(),
      };
      this.salvarContatoParaAutoComplete({
        tipo: this.tipoCampoContato,
        contato: this.camposTeste.contato,
      });
      try {
        await this.testarEvento(payload);
        this.showSnackBar({
          text: `Evento enviado`,
        });
        this.fecharDialog();
      } catch (err) {
        this.showSnackBar({
          text: `Erro ao enviar evento`,
        });
      } finally {
        this.salvando = false;
      }
    },
  },
  watch: {
    dialog(newValue) {
      if (!newValue) {
        this.$refs.form.reset();
        this.tipoCampoContato = null;
        this.labelCampoId = null;
        this.camposTeste = {
          contato: null,
          id: null,
        };
        return;
      } else {
        this.definirCamposForm();
      }
    },
  },
};
</script>

<style scoped></style>
