<template>
  <v-dialog persistent v-model="dialog" :width="400">
    <v-form v-model="isFormValid" @submit.prevent="salvarPrecificacaoEvento" ref="form">
      <v-card>
        <v-card-title class="text-h5 title-card flex align-center">
          {{ evento.id ? "Editar" : "Nova" }} Precificação de Evento
        </v-card-title>
        <v-card-subtitle v-show="evento.id">
          #{{ evento.id }} - {{ evento.categoria_apelido }}
        </v-card-subtitle>
        <v-card-text class="pt-6">
          <div>
            <v-text-field
              class="mb-1"
              v-model="evento.categoria"
              label="Categoria*"
              outlined
              :autofocus="!evento.id"
              required
              :rules="[rules.required]"
              :disabled="evento.id != null"
            ></v-text-field>

            <v-text-field
              class="mb-1"
              v-model="evento.categoria_apelido"
              label="Apelido"
              :autofocus="evento.id != null"
              outlined
            ></v-text-field>

            <v-text-field
              class="mb-1"
              v-model="evento.descricao"
              label="Descrição"
              outlined
            ></v-text-field>

            <v-text-field
              class="mb-1"
              label="Valor*"
              outlined
              type="number"
              hide-spin-buttons
              min="0"
              v-model="evento.valor"
              :rules="[rules.required]"
              required
            ></v-text-field>

            <div class="d-flex align-start">
              <v-checkbox class="mt-0 pt-0" label="Franquia" v-model="evento.franquia"></v-checkbox>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="gray" class="ml-2 pt-1" v-bind="attrs" v-on="on" small
                    >mdi-help-circle</v-icon
                  >
                </template>
                <span>Informa se o evento entrará no cálculo das franquias</span>
              </v-tooltip>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog()"> cancelar </v-btn>
          <v-btn :disabled="!isFormValid" color="primary" type="submit" :loading="salvando"> salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      isFormValid: false,
      rules: {
        required: (v) => !!v || "Campo Obrigatorio",
      },
      regua: null,
      dialog: false,
      salvando: false,
      carregando: false,
      evento: {
        id: null,
        categoria: null,
        categoria_apelido: null,
        descricao: null,
        valor: null,
        franquia: false,
      },
    };
  },
  computed: {
    ...mapGetters("empresa", ["empresaSelecionada"]),
  },
  methods: {
    ...mapActions("reguas", ["getReguaBrokers", "postReguaBrokers"]),
    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),

    async openDialog() {
      this.dialog = true;
    },

    closeDialog() {
      this.dialog = false;
      this.salvando = false;
      this.evento = {
        id: null,
        categoria: null,
        categoria_apelido: null,
        descricao: null,
        valor: null,
        franquia: false,
      };
      this.$refs.form.resetValidation();
    },

    async salvarPrecificacaoEvento() {
      this.salvando = true;
      try {     
        this.$emit("salvar", this.evento);
        // this.closeDialog();
      } catch (e) {
        console.error("Erro ao salvar precificação", e);
        this.showSnackBar({
          text: `Erro ao salvar precificação`,
        });
      } finally {
        this.salvando = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
