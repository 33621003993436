import store from "../../store/index";

export const checkCredentials = function (payload) {
  const grantedCredentials = payload;
  const { permissoes: credentials } = store.getters["auth/getUsuario"];
  let granted = false;
  try {
    credentials.forEach((credential) => {
      if (!grantedCredentials.some((rule) => rule == credential)) return;
      granted = true;
    });
    return granted;
  } catch {
    return false;
  }
};

export function debounce(fn, delay) {
  let timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    const args = arguments;
    const that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
}

export function formataCasasDecimais(value) {
  if (!value) return 0;
  if ((typeof value !== "number" && typeof value !== "string") || isNaN(value))
    return 0;

  const valorDecimal = parseFloat(value);
  const decimais = String(valorDecimal).split(".");

  if (decimais[1]) {
    const arrDecimais = Array.from(decimais[1]);
    for (let i = arrDecimais.length + 1; i == 0; i--) {
      if (arrDecimais[i] === 0) arrDecimais.pop();
      else break;
    }
  }

  const numAbsoluto = Math.abs(decimais[0]);
  let numDecimal = 0;

  if (!decimais[1]) {
    numDecimal = "00";
  } else {
    decimais[1].length < 2
      ? (numDecimal = decimais[1] + "0")
      : (numDecimal = decimais[1]);
  }

  if (!decimais[1]) {
    return parseFloat(`${numAbsoluto}.${numDecimal}`).toFixed(2);
  }

  return parseFloat(`${numAbsoluto}.${numDecimal}`);
}

function getRelativeLuminance(color) {
  const gammaCorrection = function (value) {
    value = value / 255;
    return value <= 0.03928 ? value / 12.92 : Math.pow((value + 0.055) / 1.055, 2.4);
  };

  if (typeof color !== 'number') {
    return 0;
  }

  const r = gammaCorrection((color >> 16) & 0xff);
  const g = gammaCorrection((color >> 8) & 0xff);
  const b = gammaCorrection(color & 0xff);

  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

function ensureGoodContrastWithWhite(color) {
  if (typeof color !== 'string' || !/^#[0-9A-Fa-f]{6}$/.test(color)) {
    return '#000000'; // Retorna preto como fallback
  }

  // Converte a cor hexadecimal para número RGB
  let hexColor = parseInt(color.slice(1), 16);
  let luminance = getRelativeLuminance(hexColor);

  // Calcula o contraste com branco
  let contrastRatio = (1.0 + 0.05) / (luminance + 0.05);

  // Critério WCAG: 4.5:1 para texto pequeno
  let minimumContrast = 4.5;

  if (contrastRatio >= minimumContrast) {
    return color; // A cor já possui bom contraste
  }

  // Ajusta a cor até atingir o contraste necessário
  const adjustLuminance = function (hexColor, factor) {
    const r = Math.min(255, Math.max(0, Math.round(((hexColor >> 16) & 0xff) * factor)));
    const g = Math.min(255, Math.max(0, Math.round(((hexColor >> 8) & 0xff) * factor)));
    const b = Math.min(255, Math.max(0, Math.round((hexColor & 0xff) * factor)));
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
  };

  // Se contraste for insuficiente, escurece a cor
  let factor = 0.9; // Escurece a cor em 10% por iteração
  while (contrastRatio < minimumContrast) {
    color = adjustLuminance(hexColor, factor);
    hexColor = parseInt(color.slice(1), 16);
    luminance = getRelativeLuminance(hexColor);
    contrastRatio = (1.0 + 0.05) / (luminance + 0.05);
    factor -= 0.05; // Escurece progressivamente
    if (factor <= 0.1) {
      console.warn("Não foi possível garantir contraste adequado. Usando fallback.");
      return '#000000'; // Retorna preto como fallback
    }
  }

  return color;
};

export function stringToColor (str) {
  if (!str) { return "#000000"; }

  // Gera uma cor com base no hash da string
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = "#" + (hash & 0x00ffffff).toString(16).padStart(6, "0").toUpperCase();

  const adjustColor = function (color) {
    const hex = parseInt(color.slice(1), 16);
    const r = Math.min(255, (hex >> 16) + 20); // Incrementa vermelho
    const g = Math.min(255, ((hex >> 8) & 0xff) + 20); // Incrementa verde
    const b = Math.min(255, (hex & 0xff) + 20); // Incrementa azul
    return "#" + ((r << 16) | (g << 8) | b).toString(16).padStart(6, "0").toUpperCase();
  };

  return ensureGoodContrastWithWhite(adjustColor(color));
};