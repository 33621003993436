<template>
  <div v-html="prepareDay" />
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "DayTimeline",
  props: {
    day: {
      type: Number,
      require: true,
    },
  },
  computed: {
    ...mapState("regua", ["regua"]),
    prepareDay() {
      let html = "<span>";
      if (this.day == 0) {
        html += "<span class='ml-data-referencia'>" + this.getDataReferencia + "</span>";
      } else if (this.regua.tipo == "IMPLANTACAO" && this.day < 0) {
        html = "";
      } else {
        let timeCourse = this.day > 0 ? " depois" : " antes";
        let plural = this.day == 1 || this.day == -1 ? "" : "s";
        html +=
          "<b style='font-size: 18px;'>" +
          (this.day + "</b> dia").replace("-", "") +
          plural +
          timeCourse;
      }
      return html + "</span>";
    },
    getDataReferencia() {
      if (!this.regua || !this.regua.tipo) {
        return "";
      }
      const datas = {
        IMPLANTACAO: "Implantação",
        VENCIMENTO_BOLETO: "Vencimento",
        VENCIMENTO_DEBITO: "Vencimento",
        VENCIMENTO_CREDITO: "Vencimento",
        VENCIMENTO_PIX: "Vencimento",
        TOLERANCIA_BOLETO: "Tolerância",
        TOLERANCIA_DEBITO: "Tolerância",
        TOLERANCIA_CREDITO: "Tolerância",
        TOLERANCIA_PIX: "Tolerância",
        ANIVERSARIO_DO_CLIENTE: "Aniversário",
        BOLETO_SOLICITADO: "Solicitação",
        INICIO_CONTRATO: "Início do Contrato",
        TERMINO_CONTRATO: "Término do Contrato",
        CONTRATO_FINALIZADO: "Contrato Finalizado",
        CONTRATO_CANCELADO: "Contrato Cancelado",
        PAGAMENTO_BOLETO_RECEBIDO: "Pagamento Recebido",
        PAGAMENTO_DEBITO_RECEBIDO: "Pagamento Recebido",
        PAGAMENTO_CREDITO_RECEBIDO: "Pagamento Recebido",
        PAGAMENTO_PIX_RECEBIDO: "Pagamento Recebido",
        PARCELA_BOLETO_CRIADA: "Parcela Criada",
        PARCELA_DEBITO_CRIADA: "Parcela Criada",
        PARCELA_CREDITO_CRIADA: "Parcela Criada",
        PARCELA_PIX_CRIADA: "Parcela Criada",
        MOVIMENTACAO: "Movimentação",
      };
      return datas[this.regua.tipo] || "";
    },
  },
};
</script>

<style>
.ml-data-referencia {
  font-size: 18px;
  font-weight: bold;
}
</style>
